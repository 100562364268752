<!--
 * @Author: your name
 * @Date: 2022-01-25 14:25:20
 * @LastEditTime: 2022-03-11 17:38:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \web-pc\src\views\thirdLogin.vue
-->

<template>
    <div></div>
</template>

<script>
import Api from '../api/home';
import axios from 'axios';
export default {
    data() {
        return {};
    },
    methods: {},
    created() {
        let url = window.location.href;
        let params = {
            code: this.$route.query.CODE,
            url,
            client_id: 'basic-web',
            client_secret: '1q2w3e*',
        };
        axios
            .post(`http://exam.chinaopenschool.com:8081/connect/connect/singletoken`, params)
            .then(res => {
                this.$message.error('singletoken' + res);
            })
            .catch(err => {
                console.warn(err);
                this.$message.error(err);
            });
    },
};
</script>

<style >
</style>